/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import { styled } from "style";

import Accordion from "components/Accordion";
import BlockContent from "components/BlockContent";
import Icon from "components/Icon";
import Section from "components/SectionWrapper";
import YouTubeEmbed from "components/YoutubeEmbed";

const defaultProps = {
  variant: "default",
};

const Wrapper = styled(Section)({
  py: 5.5,
  backgroundColor: "violet.900",
  color: "violet.50",
});

const Container = styled("div")({
  position: "relative",
  display: "grid",
  gridTemplateColumns: [
    "minmax(0px, 1fr)",
    null,
    "repeat(2, minmax(0px, 1fr))",
  ],
  gridTemplateRows: "auto",
  gridTemplateAreas: ["'text' 'list'", "'text' 'list'", "'list text'"],
  gap: 4,
  alignItems: "center",
  mx: "auto",
  px: 3,
  width: "100%",
  maxWidth: "xl",
});

const Text = styled(BlockContent, { forwardProps: ["blocks"] })({
  gridArea: "text",
  "& > *": {
    maxWidth: "sm",
    mx: [3, 4],
  },
  h2: {
    color: "violet",
    variant: "text.sectionHeading.major",
  },
  "[data-button]": {
    color: "violet.900",
  },
});

const List = styled("ul")({
  gridArea: "list",
});

const YoutubePlaylist = ({ text, videos, variant, ...props }) => {
  return (
    <Wrapper>
      <StaticImage
        alt=""
        formats={["auto", "webp", "avif"]}
        placeholder="none"
        layout="fullWidth"
        role="presentation"
        src="../assets/images/marker-background-violet.jpg"
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          borderRadius: "inherit",
          userSelect: "none",
          pointerEvents: "none",
        }}
      />
      <Container>
        {text && <Text blocks={text} />}
        {videos && videos.length > 0 && (
          <List as={Accordion}>
            {videos.map(item => (
              <React.Fragment key={item._key}>
                <Accordion.Heading>
                  <Icon icon="youtube" />
                  &ensp;{item.title}
                </Accordion.Heading>
                <Accordion.Panel>
                  <YouTubeEmbed
                    videoId={getVideoId(item.url)}
                    title={item.title}
                    modestbranding
                  />
                </Accordion.Panel>
              </React.Fragment>
            ))}
          </List>
        )}
      </Container>
    </Wrapper>
  );
};

YoutubePlaylist.defaultProps = defaultProps;

export default YoutubePlaylist;

function getVideoId(_url) {
  let url;
  try {
    url = new URL(_url);
  } catch {
    console.warn(`Couldn't get video ID from url string "${_url}"`);
    return null;
  }

  const { searchParams } = url;
  const v = searchParams.get("v");

  return v || null;
}
