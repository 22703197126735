import React from "react";

import { filterProps } from "style";

import Box from "components/Box";

const iframePropNames = [
  "allow",
  "allowFullScreen",
  "allowPaymentRequest",
  "csp",
  "height",
  "importance",
  "loading",
  "name",
  "referrerPolicy",
  "sandbox",
  "src",
  "srcdoc",
  "width",
];

const style = {
  container: {
    position: "relative",
    overflow: "hidden",
  },
  sizeHolder: {
    position: "relative",
    display: "block",
    width: "100%",
  },
  iframe: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    border: "none",
  },
};

const { getFilteredProps, getRemainingProps } = filterProps(iframePropNames);

const Iframe = ({ src, sx, width, height, ...props }) => {
  return (
    <Box
      sx={{ ...style.container, ...(sx || {}) }}
      {...getRemainingProps(props)}
    >
      <Box
        as="span"
        aria-hidden
        sx={style.sizeHolder}
        style={{ paddingBottom: `calc(100% / (${width} / ${height}))` }}
      />
      <Box
        as="iframe"
        src={src}
        frameborder="0"
        width={width}
        height={height}
        sx={style.iframe}
        {...getFilteredProps(props)}
      />
    </Box>
  );
};

export default Iframe;
