import React from "react";
import {
  Accordion as ReachAccordion,
  AccordionItem as ReachAccordionItem,
  AccordionButton as ReachAccordionButton,
  AccordionPanel as ReachAccordionPanel,
} from "@reach/accordion";

import { styled } from "style";

import Icon from "components/Icon";

const AccordionPanel = styled(ReachAccordionPanel)({
  borderBottomLeftRadius: "sm",
  borderBottomRightRadius: "sm",
});

const AccordionItem = styled(ReachAccordionItem)({
  mb: 2,
  borderRadius: "sm",
  maxWidth: "100%",
  "&[data-state='collapsed']": {
    px: [3, 4],
  },
  "&[data-state='open']": {
    p: 1,
    bg: "violet",
    boxShadow: "xl",
  },
});

const AccordionButton = styled(
  ReachAccordionButton,
  {}
)({
  position: "relative",
  display: "flex",
  alignItems: "center",
  width: "100%",
  px: 3.5,
  py: 2.5,
  bg: "violet.500",
  borderColor: "violet.500",
  borderRadius: "md",
  textAlign: "left",
  color: "violet.100",
  fontSize: 1,
  ":hover": {
    bg: "violet.500",
    borderColor: "violet.500",
    color: "background",
  },
  ":focus": {
    zIndex: 1,
  },
  "&[aria-expanded='true']": {
    borderRadius: 0,
    bg: "violet",
    borderColor: "violet",
    color: "background",
    fontSize: 2,
    "[data-icon^='chevron']": {
      display: "none",
    },
  },
  "[data-icon^='chevron']": {
    ml: "auto",
  },
});

const Container = styled(ReachAccordion, { forwardProps: ["defaultIndex"] })({
  // width: "100%",
  maxWidth: "100%",
});

const Heading = styled("h3")({
  display: "block",
  maxWidth: "100%",
  // m: 0,
  // mb: 0,
});

const Span = styled("span")({
  width: "100%",
  maxWidth: "100%",
  pr: 2,
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
});

const Accordion = ({ children, ...props }) => (
  <Container defaultIndex={0} {...props}>
    {React.Children.map(children, child => {
      if (child.props.name !== "AccordionItem") {
        return <AccordionItem>{child}</AccordionItem>;
      }

      return child;
    })}
  </Container>
);

Accordion.Panel = AccordionPanel;
Accordion.Heading = AccordionHeading;
Accordion.Item = AccordionItem;

export default Accordion;

function AccordionHeading({ children, ...props }) {
  return (
    <Heading {...props}>
      <AccordionButton as={ReachAccordionButton} variant="secondary">
        <Span>{children}</Span>
        <Icon icon="chevron-down" />
      </AccordionButton>
    </Heading>
  );
}
