import React from "react";
import queryString from "query-string";

import { camelCase } from "utils/formatting";
import { filterProps } from "style";

import Box from "components/Box";
import Iframe from "components/Iframe";

const defaultProps = {
  width: 640,
  height: 360,
};

const youtubeSearchParams = [
  "autoplay",
  "cc_lang_pref",
  "cc_load_policy",
  "color",
  "controls",
  "disablekb",
  "enablejsapi",
  "end",
  "fs",
  "hl",
  "iv_load_policy",
  "list",
  "listType",
  "loop",
  "modestbranding",
  "origin",
  "playlist",
  "playsinline",
  "rel",
  "showinfo",
  "start",
  "widget_referrer",
];

const youtubeParamPropNames = youtubeSearchParams.map(camelCase);

const { getFilteredProps, getRemainingProps } = filterProps(
  youtubeParamPropNames
);

const YouTubeEmbed = ({ title, videoId, width, height, sx, ...props }) => {
  const [touched, setState] = React.useState();

  if (!videoId) {
    return null;
  }

  const clickHandler = () => {
    setState(true);
  };

  if (!touched) {
    return (
      <Box
        sx={{ position: "relative", bg: "#212121", ...(sx || {}) }}
        {...getRemainingProps(props)}
      >
        <Box
          as="span"
          sx={{
            display: "block",
            width: "100%",
            height: 0,
            pb: `calc(100%*${height}/${width})`,
          }}
        />
        <Box
          as="img"
          src={`https://img.youtube.com/vi/${videoId}/0.jpg`}
          alt={title}
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
          width={width}
          height={height}
        />
        <PlayButton
          onClick={clickHandler}
          aria-label={`Play YouTube video "${title}"`}
        />
      </Box>
    );
  }

  const params = { ...getFilteredProps(props), autoplay: 1 };
  const search = params ? "?" + queryString.stringify(params) : "";
  const src = `https://www.youtube.com/embed/${videoId}${search}`;

  return (
    <Iframe
      src={src}
      allow="autoplay; encrypted-media"
      allowFullScreen="true"
      width={width}
      height={height}
      sx={{ bg: "#212121" }}
      {...getRemainingProps(props)}
    />
  );
};

YouTubeEmbed.displayName = "YouTubeEmbed";

YouTubeEmbed.defaultProps = defaultProps;

function PlayButton(props) {
  return (
    <Box
      as="button"
      role="button"
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "none",
        background: "none",
        cursor: "pointer",
        path: {
          transition:
            "fill .1s cubic-bezier(0.4,0.0,1,1),fill-opacity .1s cubic-bezier(0.4,0.0,1,1)",
          fill: "#212121",
          fillOpacity: 0.8,
        },
        ":hover path, :focus path": {
          fill: "#f00",
          fillOpacity: 1,
        },
        "path:last-of-type": {
          fill: "#fff",
          fillOpacity: 1,
        },
      }}
      {...props}
    >
      <svg as="svg" version="1.1" viewBox="0 0 68 48" width="68" height="48">
        <path
          fill="#f00"
          d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"
        />
        <path fill="#fff" d="M 45,24 27,14 27,34" />
      </svg>
    </Box>
  );
}

export default YouTubeEmbed;
